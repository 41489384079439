import { FlowSpec } from '@digibee/flow';
import { ActorRefFrom, assign, createMachine } from 'xstate';

import AIAssistantAPI from '~/api/designAIAssistant';
import i18n from '~/common/helpers/i18n';

type Message = {
  sender: 'user' | 'ai';
  text: string;
  flowSpec?: FlowSpec;
  json?: Record<string, unknown>;
  isOffTopic?: boolean;
};

export type GlobalSidePanelContext = {
  history: Message[];
  prompt: string;
};

export type GlobalSidePanelEvents =
  | { type: 'TYPE'; text: string }
  | { type: 'SEND_MESSAGE' }
  | { type: 'RETRY' }
  | { type: 'CLEAR' }
  | { type: 'CANCEL' };

export type GlobalSidePanelServices = {
  sendMessage: {
    data: Message;
  };
};

const aiChatMachine = () =>
  createMachine(
    {
      /** @xstate-layout N4IgpgJg5mDOIC5QEECSBhAFgQwC4DoBLCAGzAGIBlAUQDkARAfQFlrLLkBxagbQAYAuolAAHAPaxCuQmIB2wkAA9EAWgBs+AOxqArAEYAzHzUAOPdoAsATh0mANCACeqtRfx6d1gEwWLBqx58Xl4AviEOaFh4RKQU6AAy1MgASvxCSCDiktJyCsoIKl56bsVWJmoGJn5WRZoOzghWmvgGOnwBVXwmXm0mYREYOASwYLIQhLJQ5BByYESyAG5iANZzI2PMcLDYMGkKWVIy8hn5Jnz4PhaaOqYVZT31iK34VhblbTV8BppeTf0gkSG+HW40m5DAACcIWIIfgRCQ8AAzGEAW2Bowgm1g212gn2EkOuROiD8JnwJgpeipXmMxjUVkeCD0X3wfAspisZQM1lemgM-0B0Uh0Ih5GS1AAKskAJp7DIHHLHUD5Aw+fA6KxGTTaKxBamM5l6dXsryaK4BPi2coCwZCqEw8joZC0dDUeJy0QExV5RDM84GfzFSkmVpqXQGnQGclGWx6IpeMNqUL-WRiCBwBSC3D47JHH0FBNedzWcqVaq1RkqDxGq6aEMGYo6HQ+fnhAG2gjEMg5wlKpSqExWF4dQcavzGAKM55mCw03xnKxqdqLm1RYYYiZQHve4kIHo6ck3Gmm16-IwWSueLTs+k9CyeLyDiyroHCmHbvO7gNFkPN3Q0pMgm5A1tAuLwG1sXUkwCbQwjCIA */
      id: 'AIChat',
      tsTypes: {} as import('./AIChat.machine.typegen').Typegen0,
      schema: {
        context: {} as GlobalSidePanelContext,
        events: {} as GlobalSidePanelEvents,
        services: {} as GlobalSidePanelServices
      },
      context: {
        history: [],
        prompt: ''
      },
      initial: 'idle',
      on: {
        TYPE: {
          actions: 'setPrompt'
        },
        CLEAR: {
          target: 'idle',
          actions: 'clearHistory'
        }
      },
      states: {
        idle: {
          on: {
            SEND_MESSAGE: {
              target: 'sending',
              actions: ['pushPromptMessage', 'scrollDown']
            }
          }
        },
        sending: {
          invoke: {
            id: 'sendMessage',
            src: 'sendMessage',
            onDone: {
              target: 'idle',
              actions: ['pushMessage', 'scrollDown']
            },
            onError: 'error'
          }
        },
        error: {
          on: {
            RETRY: 'sending',
            SEND_MESSAGE: {
              target: 'sending',
              actions: ['popLastMessage', 'pushPromptMessage', 'scrollDown']
            },
            CANCEL: {
              target: 'idle',
              actions: 'popMessage'
            }
          }
        }
      }
    },
    {
      actions: {
        setPrompt: assign({
          prompt: (context, event) => event.text || ''
        }),
        popLastMessage: assign({
          history: context => context.history.slice(0, -1)
        }),
        pushPromptMessage: assign({
          prompt: '',
          history: context => [
            ...context.history,
            {
              sender: 'user' as const,
              text: context.prompt || ''
            }
          ]
        }),
        pushMessage: assign({
          history: (context, event) => [...context.history, event.data]
        }),
        popMessage: assign({
          history: context => context.history.slice(0, -1)
        }),
        clearHistory: assign({
          history: []
        })
      },
      services: {
        sendMessage: async context => {
          const prompt = context.history[context.history.length - 1].text;

          const history = context.history
            .slice(0, context.history.length - 1)
            .filter(({ isOffTopic }, index, list) => {
              const nextMessage = list[index + 1];
              if (isOffTopic || nextMessage?.isOffTopic) return false;
              return true;
            })
            .map(message => ({ ...message, isOffTopic: undefined }));

          const aiMessage = await AIAssistantAPI.sendAIChatMessage({
            prompt,
            history
          });

          return {
            sender: 'ai' as const,
            text: aiMessage.isOffTopic
              ? i18n.t('label.ai_assistant_offtopic_message')
              : aiMessage.text,
            flowSpec: aiMessage.flowSpec,
            isOffTopic: aiMessage.isOffTopic
          };
        }
      }
    }
  );

export type AIChatMachineActor = ActorRefFrom<typeof aiChatMachine>;

export default aiChatMachine;
