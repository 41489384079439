import Immutable from 'seamless-immutable';

// Locals
import { license } from '~/api';
import i18n from '~/common/helpers/i18n';
import { csvDownload } from '~/common/helpers/jsonToCsv';

const initialState = Immutable({
  current: '',

  getRealm: null,
  license: null,
  usageList: null,
  pipelinesUsage: null,
  limitDetail: null,
  proposalUpsell: null,

  loadingLimitDetail: false,
  loadingPipelineUsage: false,
  loadingExport: false,
  loadingLicense: false,
  loadingLimitOfUse: false,
  loadingProposalUpsell: false,

  contacts: null,
  openContacts: false,

  loadingConsumptionModel: false,
  // consumptionModelData: null,
  consumptionModelData: {
    "granted": "4525.25",
    "spent": "125.78",
    "available": "2500.75",
    "overage": "0.0",
    "standard": "1250.20",
    "currencyCode": "USD",
    "costPerMetric": {
      "gbSecond": "500.75",
      "numberExecution": "150.20",
      "egress": "300.45",
    },
    "costPerEnvironment": [
      {
        "env": "prod",
        "cost": "200.30"
      },
      {
        "env": "test",
        "cost": "320.50"
      },
      {
        "env": "homolog",
        "cost": "90.50"
      },
      {
        "env": "other",
        "cost": "150.50"
      },
    ],
    "monthOnMonth": [
      {
        "month": "1",
        "value": {
          "prod": "1600.2",
          "test": "1100.2",
          "homolog": "600.85"
        }
      },
      {
        "month": "2",
        "value": {
          "prod": "1800.2",
          "test": "1300.2",
          "homolog": "850.85"
        }
      },
      {
        "month": "3",
        "value": {
          "prod": "2000.2",
          "test": "1100.2",
          "homolog": "300.85"
        }
      },
      {
        "month": "4",
        "value": {
          "prod": "3000.2",
          "test": "500.2",
          "homolog": "300.85"
        }
      },
      {
        "month": "5",
        "value": {
          "prod": "4000.2",
          "test": "300.2",
          "homolog": "200.85"
        }
      },
      {
        "month": "6",
        "value": {
          "prod": "1200.2",
          "test": "3000.2",
          "homolog": "700.85"
        }
      },
      {
        "month": "7",
        "value": {
          "prod": "900.2",
          "test": "2500.2",
          "homolog": "300.85"
        }
      },
      {
        "month": "8",
        "value": {
          "prod": "4000.2",
          "test": "200.2",
          "homolog": "100.85"
        }
      },
      {
        "month": "9",
        "value": {
          "prod": "2900.2",
          "test": "1100.2",
          "homolog": "500.85"
        }
      },
      {
        "month": "10",
        "value": {
          "prod": "1500.2",
          "test": "1300.2",
          "homolog": "1200.85"
        }
      },
      {
        "month": "11",
        "value": {
          "prod": "3500.2",
          "test": "1400.2",
          "homolog": "400.85"
        }
      },
      {
        "month": "12",
        "value": {
          "prod": "1800.2",
          "test": "2500.2",
          "homolog": "1800.85"
        }
      },
    ],
    "consumptionByProjects": [
      {
        "project": "Project A",
        "projectDescription": "Description of Project A",
        "totalPipelines": 5,
        "environments": {
          "prod": "1000.50",
          "test": "500.25"
        },
        "pipelines": [
          {
            "name": "Pipeline 1",
            "environment": "prod",
            "gbSecond": {
              "spentMetric": "300.75",
              "costCurrency": "1500.50"
            },
            "numberOfExecution": {
              "spentMetric": "1200.30",
              "costCurrency": "500.75"
            },
            "egress": {
              "spentMetric": "200.10",
              "costCurrency": "100.05"
            }
          }
        ]
      }
    ]
  },
});

const licenseModel = {
  name: 'license',
  state: initialState,
  reducers: {
    setEntity(state, realms) {
      return state.merge({ ...realms });
    },
    setRealm(state, realm) {
      return state.merge({ current: realm });
    },
    setGetRealm(state, realm) {
      return state.merge({ getRealm: realm });
    },
    setLicense(state, licenseData) {
      return state.merge({ license: licenseData });
    },
    setUsageList(state, usageList) {
      return state.merge({ usageList });
    },
    setLimitOfUse(state, limitOfUse) {
      return state.merge({ limitOfUse });
    },
    setPipelinesUsage(state, pipelinesUsage) {
      return state.merge({ pipelinesUsage });
    },
    setLimitDetail(state, limitDetail) {
      return state.merge({ limitDetail });
    },
    setProposalUpsell(state, proposalUpsell) {
      return state.merge({ proposalUpsell });
    },
    setLoadingPipelineUsage(state, loadingPipelineUsage) {
      return state.merge({ loadingPipelineUsage });
    },
    setLoadingExport(state, loadingExport) {
      return state.merge({ loadingExport });
    },
    setLoadingLicense(state, loadingLicense) {
      return state.merge({ loadingLicense });
    },
    setLoadingLimitOfUse(state, loadingLimitOfUse) {
      return state.merge({ loadingLimitOfUse });
    },
    setLoadingLimitDetail(state, loadingLimitDetail) {
      return state.merge({ loadingLimitDetail });
    },
    setLoadingConsumptionModel(state, loadingConsumptionModel) {
      return state.merge({ loadingConsumptionModel });
    },
    setContacts(state, contacts) {
      return state.merge({ contacts });
    },
    setLoadingProposalUpsell(state, loadingProposalUpsell) {
      return state.merge({ loadingProposalUpsell });
    },
    setOpenContacts(state, openContacts) {
      return state.merge({ openContacts });
    },
    setConsumptionModelData(state, consumptionModelData) {
      return state.merge({ consumptionModelData });
    }
  },
  effects: dispatch => ({
    async getLicense(params, state) {
      try {
        dispatch.license.setLoadingLicense(true);
        const { data } = await license.getLicense({
          realm: state.application.realm.realm,
          projectsInformation: params?.projectsInformation,
          environment: 'all'
        });
        dispatch.license.setLicense(data?.license);
        if (params?.projectsInformation) {
          dispatch.license.setUsageList(
            data?.license?.projectsInformation?.projects
          );
        }
      } catch (e) {
        dispatch.license.setLicense(null);
      } finally {
        dispatch.license.setLoadingLicense(false);
      }
    },
    async getLimitOfUse(params, state) {
      try {
        dispatch.license.setLoadingLimitOfUse(true);
        const { data } = await license.getLimitOfUse({
          realm: state.application.realm.realm,
          environment: params?.environment,
          isGlobal: params?.isGlobal,
          pipeline: params?.pipeline,
          status: params?.status
        });
        dispatch.license.setLimitOfUse(data?.limitOfUse);
        dispatch.license.setLoadingLimitOfUse(false);
      } catch (e) {
        dispatch.license.setLimitOfUse(null);
        dispatch.license.setLoadingLimitOfUse(false);
      }
    },
    async getPipelinesUsage(params, state) {
      try {
        dispatch.license.setLoadingPipelineUsage(true);
        const data = await license.getPipelinesUsage({
          realm: state.application.realm.realm,
          environment: params?.environment,
          project: params?.project
        });
        dispatch.license.setPipelinesUsage(data?.pipelinesUsage);
        dispatch.license.setLoadingPipelineUsage(false);
      } catch (e) {
        dispatch.license.setPipelinesUsage(null);
        dispatch.license.setLoadingPipelineUsage(false);
      }
    },
    async getLimitOfUseDetail(params, state) {
      try {
        dispatch.license.setLoadingLimitDetail(true);
        const data = await license.getLimitOfUseDetail({
          realm: state.application.realm.realm,
          environment: params?.environment,
          type: params?.type,
          dateFrom: params?.dateFrom,
          dateTo: params?.dateTo
        });
        dispatch.license.setLimitDetail(data?.limitOfUseDetail);
        dispatch.license.setLoadingLimitDetail(false);
      } catch (e) {
        dispatch.license.setLimitDetail(null);
        dispatch.license.setLoadingLimitDetail(false);
      }
    },
    async getProposalUpsell(params, state) {
      try {
        dispatch.license.setLoadingProposalUpsell(true);
        const data = await license.getProposalUpsell({
          realm: state.application.realm.realm,
          environment: params?.environment
        });
        dispatch.license.setProposalUpsell(data?.proposalUpsell);
        dispatch.license.setLoadingProposalUpsell(false);
      } catch (e) {
        dispatch.license.setProposalUpsell(null);
        dispatch.license.setLoadingProposalUpsell(false);
      }
    },
    async exportUsageList(params, state) {
      try {
        dispatch.license.setLoadingExport(true);
        const data = await license.getPipelinesUsage({
          realm: state.application.realm.realm,
          environment: params?.environment
        });
        dispatch.license.setPipelinesUsage(data?.pipelinesUsage);
        csvDownload({
          data: data?.pipelinesUsage?.deployments?.map(usage => ({
            project: usage?.projectName,
            projectDescription: usage?.projectDescription,
            concurrentExecution: usage?.concurrentExecution,
            pipelineVersion: usage?.pipelineVersion,
            environment: usage?.environment,
            pipeline: usage?.pipelineName,
            size: usage?.size,
            replicas: usage?.replica,
            rtu: usage?.rtu
          })),
          filename: `usage-list | ${new Date().toISOString().split('T')[0]}`,
          delimiter: ',',
          headers: params?.headers
        });
        dispatch.license.setLoadingExport(false);
        dispatch.snackbar.create({ text: i18n.t('label.download_done_msg_success') })
      } catch (e) {
        dispatch.license.setLoadingExport(false);
      }
    },
    async getContacts(_, state) {
      try {
        const { data } = await license.getRealmContacts(
          state.application.realm.realm
        );
        dispatch.license.setContacts(data?.realmContacts);
      } catch (e) {
        dispatch.license.setContacts(null);
      }
    }
  })
};

export default licenseModel;
