import { Text, Dialog, Button, Checkbox, Box } from '@digibee/beehive-ui';
import { iff } from '@digibee/control-statements';


import * as Elements from './Eula.elements';
import { EulaProps } from './Eula.enhancer';

import i18n from '~/common/helpers/i18n';
import { Divider } from '~/scenes/Runtime/scenes/Run/components/CardRuntime/OldCardRuntime.elements';


const Eula = ({
  open,
  onCancel,
  data,
  onRefuse,
  onPostpone,
  onAccept,
  onTryagain,
  checked,
  setChecked
}: EulaProps) => (
  <Dialog.Root open={open} data-testid='send-eula-dialog'>
    <Dialog.Content
      style={{ padding: '0, 24px', maxWidth: '720px', maxHeight: 'none' }}
    >
      <Dialog.Header>
        <Text
          variant='b1'
          as='h4'
          style={{ fontWeight: 'bold', padding: '24px 20px 0px 24px' }}
        >
          {i18n.t('scenes.eula.labels.license_term')}
        </Text>
      </Dialog.Header>
      <Dialog.Body style={{ padding: '0px 24px' }}>
        <Elements.EulaWrapper>
          <Text dangerouslySetInnerHTML={{ __html: data?.term || '' }} />
        </Elements.EulaWrapper>
      </Dialog.Body>
      <Divider />
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          padding: '20px'
        }}
      >
        <Checkbox
          label={i18n.t('scenes.eula.labels.accept_term')}
          // @ts-ignore
          onCheckedChange={newChecked => setChecked(newChecked)}
          defaultChecked={Boolean(data?.acceptanceDate)}
          disabled={Boolean(data?.acceptanceDate)}
        />
      </Box>
      {iff(!data?.expired && !data?.acceptanceDate, () => (
        <Text
          css={{
            color: 'Blue',
            fontSize: 'small',
            marginLeft: '20px',
            marginBottom: '35px'
          }}
        >
          {`${i18n.t('scenes.eula.labels.deadline_text')} ${data?.countDown}`}
        </Text>
      ))}
      {iff(!!data?.expired && !data?.refusalDate, () => (
        <Text
          css={{
            color: '$red500',
            fontSize: 'small',
            marginLeft: '20px',
            marginBottom: '35px'
          }}
        >
          {`${i18n.t('scenes.eula.labels.eula_expired')}`}
        </Text>
      ))}
      {iff(!!data?.expired && !!data?.refusalDate, () => (
        <Text
          css={{
            color: '$red500',
            fontSize: 'small',
            marginLeft: '20px',
            marginBottom: '35px'
          }}
        >
          {`${i18n.t('scenes.eula.labels.eula_refused')}`}
        </Text>
      ))}
      <Dialog.Footer style={{ paddingLeft: '30px', paddingBottom: '30px' }}>
        {iff(!data, () => (
          <Button
            variant='primary'
            outlined
            data-testid='eula-try-again-button'
            onClick={onTryagain}
          >
            {i18n.t('action.try_again')}
          </Button>
        ))}
        {iff(Boolean(data) && !data?.acceptanceDate, () => (
          <Button
            variant='primary'
            data-testid='eula-accept-button'
            onClick={onAccept}
            disabled={!checked}
          >
            {i18n.t('scenes.eula.actions.accept')}
          </Button>
        ))}
        {iff(
          Boolean(data) &&
          !data?.lastPostponementDate &&
          !data?.acceptanceDate &&
          !data?.refusalDate,
          () => (
            <Button
              variant='primary'
              outlined
              data-testid='eula-postpone-button'
              onClick={onPostpone}
            >
              {i18n.t('scenes.eula.actions.postpone')}
            </Button>
          )
        )}
        {iff(
          Boolean(data) &&
          Boolean(data?.lastPostponementDate && !data?.acceptanceDate),
          () => (
            <Button
              variant='primary'
              outlined
              data-testid='eula-refuse-button'
              onClick={onRefuse}
            >
              {i18n.t('scenes.eula.actions.refuse')}
            </Button>
          )
        )}
        {iff(!data || Boolean(data?.acceptanceDate), () => (
          <Dialog.Close asChild>
            <Button
              variant='primary'
              outlined
              data-testid='eula-close-button'
              onClick={onCancel}
            >
              {i18n.t('scenes.eula.actions.close')}
            </Button>
          </Dialog.Close>
        ))}
      </Dialog.Footer>
    </Dialog.Content>
  </Dialog.Root>
);

export default Eula;
