import { useEffect } from 'react';
import {
  createMachine,
  AnyActorRef,
  EventObject,
  interpret,
  assign,
  AnyEventObject,
  ActionFunction
} from 'xstate';

export type BusContext = {
  actors: AnyActorRef[];
};

type BusEvents =
  | {
      type: 'DISPATCH';
      to?: string;
      event: { type: string; [key: string]: any };
    }
  | { type: 'RESPOND'; to: string; event: { type: string; [key: string]: any } }
  | { type: 'REGISTER'; actor: AnyActorRef }
  | { type: 'UNREGISTER'; actor: AnyActorRef };

const busMachine = createMachine(
  {
    id: 'bus',
    tsTypes: {} as import('./bus.machine.typegen').Typegen0,
    schema: {
      context: {} as BusContext,
      events: {} as BusEvents
    },
    context: {
      actors: []
    },
    initial: 'idle',
    states: {
      idle: {
        on: {
          REGISTER: {
            actions: ['registerActor']
          },
          UNREGISTER: {
            actions: ['unregisterActor']
          },
          DISPATCH: {
            actions: ['dispatchEvent']
          },
          RESPOND: {
            actions: ['respondEvent']
          }
        }
      }
    }
  },
  {
    actions: {
      registerActor: assign({
        actors: (context, event) => {
          // TODO: ensure unique actor ids
          return context.actors.concat(event.actor);
        }
      }),
      unregisterActor: assign({
        actors: (context, event) => {
          return context.actors.filter(
            currentActor => currentActor !== event.actor
          );
        }
      }),
      dispatchEvent(context, { to, event }) {
        if (to) {
          const actor = context.actors.find(
            currentActor => currentActor.id === to
          );

          actor?.send(event);
          return;
        }

        context.actors.forEach(currentActor => {
          currentActor.send(event);
        });
      },
      respondEvent(context, event, meta) {
        const actor = context.actors.find(
          currentActor => currentActor.id === event.to
        );
        actor?.send(event.event);
      }
    }
  }
);

const eventBus = interpret(busMachine).start();

// @ts-ignore
window.bus = eventBus;

export const getActor = <T = AnyActorRef>(machineId: string) => {
  const actors = eventBus.getSnapshot().context.actors;
  const actor = actors.find(a => a.id === machineId);
  return actor as T;
};

export const getContextSync = <TContext = any>(machineId: string) => {
  const actors = eventBus.getSnapshot().context.actors;
  const actor = actors.find(a => a.id === machineId);
  if (!actor) return null;
  return actor.getSnapshot().context as TContext;
};

export const getContext = async <TContext = any>(machineId: string) => {
  const actors = eventBus.getSnapshot().context.actors;
  const actor = actors.find(a => a.id === machineId);
  if (!actor) return Promise.reject(`No actor ${machineId} registered`);
  return actor.getSnapshot().context as TContext;
};

export const getContextData = async <T>(machineId: string, key: string) => {
  const context = await getContext(machineId);
  if (!context[key]) return Promise.reject(`No context data for ${key}`);
  return context[key] as T;
};

export const getContextDataSync = <T>(machineId: string, key: string) => {
  const context = getContextSync(machineId);
  if (!context[key]) null;
  return context[key] as T;
};

type SendParams = {
  event: AnyEventObject;
  from?: string;
  to?: string;
};

export const send = ({ event, from, to }: SendParams) => {
  eventBus.send({ type: 'DISPATCH', to, event: { ...event, from } });
};

type SendActionOptions = {
  to?: string;
};

export const sendAction = <TContext, TEvent extends EventObject>(
  event: EventObject | ((context: TContext, event: TEvent) => EventObject),
  options: SendActionOptions = {}
): ActionFunction<TContext, TEvent> => {
  return (context, _event, meta) => {
    const machineName = meta.state?.machine?.id;

    send({
      event: event instanceof Function ? event(context, _event) : event,
      from: machineName,
      to: options.to
    });
  };
};

export const respondAction = <TContext, TEvent extends EventObject>(
  event: EventObject | ((context: TContext, event: TEvent) => EventObject),
  options: SendActionOptions = {}
): ActionFunction<TContext, TEvent> => {
  return (context, _event, meta) => {
    const machineName = meta.state?.machine?.id;

    eventBus.send({
      type: 'RESPOND',
      to: machineName as string,
      event: event instanceof Function ? event(context, _event) : event
    });
  };
};

export const forwardAction = <TContext, TEvent extends EventObject>(
  options: SendActionOptions & { as?: string } = {}
): ActionFunction<TContext, TEvent> => {
  return (context, _event, meta) => {
    const machineName = meta.state?.machine?.id;

    send({
      event: {
        ..._event,
        type: options.as
      },
      from: machineName,
      to: options.to
    });
  };
};

export const useRegisterActorOnEventBus = (actor: AnyActorRef) => {
  useEffect(() => {
    eventBus.send({ type: 'REGISTER', actor });
    return () => {
      eventBus.send({ type: 'UNREGISTER', actor });
    };
  }, []);
};

export default eventBus;
